import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LicenseInfo } from '@mui/x-license-pro';
import {msalConfig} from "./authorization/authConfig";

LicenseInfo.setLicenseKey('1759468ceb765e1b863fc0ddf31359a3Tz0xMDQ2MjUsRT0xNzY2MjM1NjExMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

const msalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>
);
