import { useMsal, useAccount } from '@azure/msal-react';
import axios, {InternalAxiosRequestConfig} from 'axios';
import { tokenRequest } from './authConfig';
import React from "react";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

interface RequestInterceptorProps {
    children: JSX.Element,
}

const AccessTokenRequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    axios.interceptors.request.use(async (config :InternalAxiosRequestConfig) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }

        const response = await instance.acquireTokenSilent({
            ...tokenRequest,
            account
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                return instance.acquireTokenPopup({
                    ...tokenRequest,
                    account
                });
            }
        });

        const bearer = `Bearer ${response?.accessToken}`;
        config!.headers!.Authorization = bearer;

        return config;
    });

    return (
        <>
            {children}
        </>
    );
};

export default AccessTokenRequestInterceptor;